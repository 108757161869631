@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Work Sans');

/*Not sure if I want to comment this out or not*/
table {
	width: auto;
	height: 200px;
	border: 0;
	border-collapse: collapse;
	margin: auto;
	overflow-x: auto;
}

.AdminSchedule {
	background-color: #e0e0e0;
	margin-top: 7rem;
	margin-bottom: 7rem;
}

.AdminSchedule th {
	border-bottom: 1px solid black;
	color: white;
	background-color: #92562e;
	text-align: center;
}

@media (max-width: 700px) {
	.AdminSchedule th {
		font-size: xx-small;
	}
}

.AdminSchedule {
	overflow-x: auto;
}

.adminVenueTableData {
	padding: 0;
}

td {
	text-align: center;
	font-family: Work Sans, sans-serif;
}

h2 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#schedulePlayers {
	width: 75%;
	height: auto;
}

@media (max-width: 430px) {
	td {
		font-size: xx-small;
	}
	.Schedule th {
		font-size: x-small;
	}
	#schedulePlayers {
		width: 110%;
	}
}

@media (max-width: 700px) {
	td {
		font-size: xx-small;
	}
	.Schedule th {
		font-size: x-small;
	}
	#schedulePlayers {
		width: 100%;
	}
}

/* iPad 9th generation */
@media (min-width: 1700px) {
	#schedulePlayers {
		width: 90%;
	}
}

#schedulePlayers tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#schedulePlayers tr:nth-child(odd) {
	background-color: white;
}

thead {
	color: white;
}

h1 {
	/* font-family: Work Sans, sans-serif; */
	font-family: Montserrat, Work Sans, sans-serif;
	/* font-size: large; */
}
/* //
//
//
//
//
// */
#adminVenueScheduleDisplay {
	width: 70%;
}
#adminVenueScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#adminVenueScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 1000px) {
	#adminVenueScheduleDisplay {
		width: 100%;
	}
}
/* //
//
//
//
//
// */
#adminLeagueScheduleDisplay {
	width: 70%;
}
#adminLeagueScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#adminLeagueScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 1000px) {
	#adminLeagueScheduleDisplay {
		width: 100%;
	}
}
