.side-drawer {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	height: 100vh;
	width: 70%;
	background: white;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

/* My iPhone longways */
@media (max-height: 700px) {
	.side-drawer {
		overflow: auto;
		padding-top: 2rem;
		height: 100%;
		font-size: small;
	}
}
