.home-header {
	width: 100%;
	height: 4rem;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	background: #ea7200;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
	padding: 0 1rem;
	z-index: 5;
}

main {
	margin-top: 5rem;
	background: #e0e0e0;
}

@media (min-width: 768px) {
	.home-header {
		justify-content: space-between;
	}
}
