.searchByVenueHeight {
	padding-top: 5.5rem;
	height: 2rem !important;
}

@media (max-width: 430px) {
	.searchByVenueHeight {
		height: 7rem !important;
	}

	.searchByVenue {
		font-size: small;
	}

	.searchByLeague {
		font-size: small;
	}
}
