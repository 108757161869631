@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Work Sans');

.Standings {
	background-color: #e0e0e0;
}

.Standings th {
	border-bottom: 1px solid black;
	color: black;
	background-color: #ea7200;
	text-align: center;
}

.Standings {
	/* overflow-x: auto; */
}

td {
	text-align: center;
	/* border: 2px solid black; */
	font-family: Work Sans, sans-serif;
}

h2 {
	text-align: center;
}

#players tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#players tr:nth-child(odd) {
	background-color: white;
}

#players {
	width: 70%;
}

thead {
	color: white;
}

h1 {
	/* font-family: Work Sans, sans-serif; */
	font-family: Montserrat, Work Sans, sans-serif;
	/* font-size: large; */
}

.tableDataTeamName a:hover {
	background-color: #ea7200;
}

@media (max-width: 430px) {
	td {
		font-size: xx-small;
	}

	.Standings th {
		font-size: x-small;
	}

	#players {
		width: 100%;
	}
}
