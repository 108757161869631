table {
	border-collapse: collapse;
	border-radius: 6px;
}

.Leagues th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #ea7200;
	text-align: center;
}

@media (max-width: 430px) {
	.Leagues th {
		font-size: x-small;
	}
}

/*This right here controls the width of the schedule screen */
.Leagues {
	background-color: #e0e0e0;
	/* overflow-x: auto; */
	width: auto;
	margin: auto;
	margin-top: 7rem;
	margin-bottom: 7rem;
	/* height: 200px; */
}

table td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px 0;
	height: 10px;
}

h2,
h5 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#leagues {
	width: 55%;
	height: auto;
}

@media (max-width: 430px) {
	#leagues {
		width: 100%;
	}
}

@media (max-width: 1700px) {
	#leagues {
		width: 100%;
	}
}

#leagues tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#leagues tr:nth-child(odd) {
	background-color: white;
}

table thead {
	padding: 10px;
}
