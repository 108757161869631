.modal {
	z-index: 100;
	/* width: 40% !important;
	height: 50% !important; */
	margin: 0 auto;
	display: block !important;
	position: fixed;
	left: 50%;
	background: white;
	right: 0;
	top: 9vh !important;
	border-radius: 8px;
}

@media (min-width: 768px) {
	.modal {
		left: calc(50% - 20rem);
		width: 40rem;
	}
}

@media (min-width: 1000px) {
	.modal {
		width: 40% !important;
		height: 85% !important;
	}
}

@media (max-width: 1700px) {
	.modal {
		width: 90% !important;
		height: 75% !important;
	}
}

.modal__header {
	width: 100%;
	padding: 1rem 0.5rem;
	background: #ea7200;
	color: white;
}

.modal__header h2 {
	margin: 0.5rem;
}

.modal__content {
	padding: 0.5rem 0.5rem;
}

.modal__footer {
	padding: 0.5rem 0.5rem;
}

.modal-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.modal-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.modal-exit {
	transform: translateY(0);
	opacity: 1;
}

.modal-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}
