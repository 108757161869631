@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Work Sans');

/*Not sure if I want to comment this out or not*/
table {
	width: auto;
	height: 200px;
	border: 0;
	border-collapse: collapse;
	margin: auto;
	/* overflow-x: auto; */
}

.h3Center {
	text-align: center;
}

.Schedule {
	background-color: #e0e0e0;
	margin-top: 2rem;
	margin-bottom: 7rem;
}

.Schedule th {
	border-bottom: 1px solid black;
	color: white;
	background-color: #ea7200;
	text-align: center;
}

.Schedule p {
	text-align: center;
}

.Schedule {
	/* overflow-x: auto; */
}

td {
	text-align: center;
	font-family: Work Sans, sans-serif;
}

h2 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#schedulePlayers {
	width: 60%;
}

@media (max-width: 430px) {
	td {
		font-size: xx-small;
	}
	.Schedule th {
		font-size: x-small;
	}
	#schedulePlayers {
		width: 100%;
	}
	.Schedule {
		width: 100%;
	}
}

@media (max-width: 700px) {
	td {
		font-size: x-small;
	}
	.Schedule th {
		font-size: x-small;
	}
	#schedulePlayers {
		width: 100%;
	}
}

/* iPad 9th generation */
@media (max-width: 1700px) {
	#schedulePlayers {
		width: 90%;
	}
}

#schedulePlayers tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#schedulePlayers tr:nth-child(odd) {
	background-color: white;
}

thead {
	color: white;
}

h1 {
	/* font-family: Work Sans, sans-serif; */
	font-family: Montserrat, Work Sans, sans-serif;
	/* font-size: large; */
}

.tableDataTeamName a:hover {
	background-color: #ea7200;
}
/* //
//
//
//
//
// */
#leagueScheduleDisplay {
	width: 70%;
}
#leagueScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#leagueScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 1000px) {
	#leagueScheduleDisplay {
		width: 100%;
	}
}
/* //
//
//
//
//
// */
#teamScheduleDisplay {
	width: 70%;
}
#teamScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#teamScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 1000px) {
	#teamScheduleDisplay {
		width: 100%;
	}
}
/* //
//
//
//
//
// */
#defaultScheduleDisplay {
	width: 70%;
}
#defaultScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#defaultScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 1000px) {
	#defaultScheduleDisplay {
		width: 100%;
	}
}

.sloths {
	width: 13%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: 2rem;
}

@media (max-width: 700px) {
	.sloths {
		width: 30%;
		display: block;
		margin-right: auto;
		margin-top: auto;
		margin-bottom: 2rem;
	}
}
