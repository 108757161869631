.home-nav-links {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home-nav-links li {
	margin: 1rem;
}

.home-nav-links a {
	border: 1px solid transparent;
	color: #06043f;
	text-decoration: none;
	padding: 0.5rem;
	border-radius: 4px;
}

.home-nav-links a:hover,
.home-nav-links a:active,
.home-nav-links a.active {
	/* background: #06043f;
	border-color: #06043f; */
	background: #006d75;
	border-color: #006d75;
	color: white;
}

.home-nav-links button {
	cursor: pointer;
	/* border: 1px solid #06043f;
	color: #06043f; */
	border: 1px solid #006d75;
	color: #006d75;
	background: transparent;
	padding: 0.5rem;
	font: inherit;
}

.home-nav-links button:focus {
	outline: none;
}

.home-nav-links button:hover,
.home-nav-links button:active {
	background: #06043f;
	color: white;
}

/************************************* //
//
//  connies ipad 
//
//*************************************/
@media (min-width: 768px) {
	.home-nav-links {
		flex-direction: row;
	}

	.home-nav-links li {
		margin: 0 0.5rem;
	}

	.home-nav-links a {
		color: white;
		text-decoration: none;
		font-size: x-small;
		font-weight: 700;
		border-radius: 4px;
	}

	.home-nav-links button {
		border: 1px solid white;
		color: white;
		background: transparent;
	}

	.home-nav-links button:hover,
	.home-nav-links button:active {
		background: #292929;
		color: white;
	}
}
/************************************* //
//
//  Connies laptop
//
//*************************************/
@media (min-width: 1200px) {
	.home-nav-links a {
		color: white;
		text-decoration: none;
		font-size: large;
		font-weight: 700;
		border-radius: 4px;
	}
}
/************************************* //
//
//  my desktop
//
//*************************************/
@media (min-width: 1600px) {
	.home-nav-links a {
		color: white;
		text-decoration: none;
		font-size: large;
		font-weight: 700;
		border-radius: 4px;
	}
}
