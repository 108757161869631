.lds-dual-ring {
	display: inline-block;
	width: 164px;
	height: 164px;
}

.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 146px;
	height: 146px;
	margin: 1px;
	border-radius: 50%;
	border: 15px solid #ea7200;
	border-color: #ea7200 transparent #ea7200 transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
