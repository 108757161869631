table {
	border-collapse: collapse;
	border-radius: 6px;
}

.Players th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}

.Players {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: 100%;
	margin-top: 7rem;
	height: 1500px;
}

td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#players {
	width: 60%;
	height: 200px;
}

@media (max-width: 700px) {
	#players {
		width: 100%;
	}
}

#players tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#players tr:nth-child(odd) {
	background-color: white;
}

thead {
	padding: 10px;
}

/* button {
	margin: 0rem !important;
} */

.cancelDeleteButtons:hover {
	background: #9f9f9f;
	color: black;
}

.add {
	font-size: large;
	text-align: center;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

/* #newLeagueButton {
	text-align: center;
	padding: 10px;
	color: white;
	border-radius: 4px;
	background-color: #92562e !important;
	border: #92562e !important;
	margin-left: 10rem !important;
	margin-bottom: 1rem;
} */

/* //
//
//
//
//
//
//
// */

.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
	margin: 0rem !important;
}
