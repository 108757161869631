/*It looks like this is CSS that everything else is looking at*/

table {
	width: 50%;
	height: 200px;
	border-collapse: collapse;
	margin: auto;
	/* overflow-x: auto; */
}

.Roster {
	background-color: #e0e0e0;
	/* overflow-x: auto; */
	margin-bottom: 3rem;
}

.Roster th {
	border-bottom: 1px solid black;
	color: white;
	background-color: #ea7200;
	text-align: center;
	font-size: large;
}

td {
	text-align: center;
	border: 2px solid black;
	font-size: medium;
}

h1 {
	text-align: center;
}

h3 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#teamSchedule {
	width: 60%;
}

#roster {
	width: 50%;
	height: auto;
}
/************************************
//
//  iPhone
//
*************************************/
@media (max-width: 430px) {
	#roster {
		width: 100%;
	}
	.playerPhoto {
		width: 35%;
	}
	img {
		width: 35% !important;
	}
	td {
		font-size: small;
	}
	.Roster th {
		font-size: xx-small;
	}
	#teamSchedule {
		width: auto;
	}
}
/************************************* //
//
//  iPhone landscape (I think)
//
//*************************************/
@media (min-width: 600px) {
	#roster {
		width: 90%;
	}
	.playerPhoto {
		width: 25%;
	}
	img {
		width: 25%;
	}
	#teamSchedule {
		width: auto;
	}
	td {
		font-size: small;
	}
	#bottomRoster {
		width: 80%;
		margin-bottom: 8rem;
	}
}

@media (max-width: 700px) {
	#roster {
		width: 80%;
	}
}
/************************************* //
//
//  connies ipad 
//
//*************************************/
@media (min-width: 768px) {
	#roster {
		width: 90%;
	}
	.playerPhoto {
		width: 25%;
	}
	img {
		width: 25%;
	}
	td {
		font-size: small;
	}
}
/************************************
//
//  connies laptop
//
*************************************/
@media (min-width: 1200px) {
	#roster {
		width: 100%;
	}
	.playerPhoto {
		width: 15%;
	}
	img {
		width: 15%;
	}
}

#roster tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#roster tr:nth-child(odd) {
	background-color: white;
}

#teamSchedule tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#teamSchedule tr:nth-child(odd) {
	background-color: white;
}

img {
	width: 9%;
}

@media (min-width: 1600px) {
	#roster {
		width: 60%;
	}
	#teamSchedule {
		width: 70%;
	}
	.playerPhoto {
		width: 15%;
	}
	img {
		width: 20%;
	}
	td {
		font-size: medium;
	}
}

thead {
	color: white;
}

.photoDiv {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.playerPhoto {
	display: block;
	margin: auto;
	border-radius: 2%;
	border: solid;
	border-width: 4px;
	border-color: black;
	/* size: 15%; */
	/* width: 9%; */
}

@media (max-width: 700px) {
	.playerPhoto {
		display: block;
		margin: auto;
		border-radius: 2%;
		border: solid;
		border-width: 4px;
		border-color: black;
		/* size: 15%; */
		width: 25%;
	}
}
