@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Work Sans');

h2 {
	/* font-family: Work Sans, sans-serif; */
	font-family: Montserrat, Work Sans, sans-serif;
}

.authentication__header {
	color: white;
	text-align: center;
}

.authentication {
	width: 90%;
	max-width: 25rem;
	margin: 7rem auto;
	text-align: center;
}

.authentication form {
	margin-bottom: 1rem;
}
