.abutton {
	font: inherit;
	padding: 0.5rem 1.5rem;
	/* border: 1px solid #EA7200; */
	border: 1px solid #92562e;
	border-radius: 4px;
	/* background: #EA7200; */
	width: max-content;
	background: #92562e;
	color: white;
	cursor: pointer;
	margin-right: 1rem;
	margin-top: 1rem;
	text-decoration: none;
	display: inline-block;
}

@media (max-width: 700px) {
	.abutton {
		font-size: x-small;
		padding-left: 10px;
		margin-left: 5%;
		margin-right: 0%;
	}
}

.abutton:focus {
	outline: none;
}

.abutton:hover,
.abutton:active {
	/* background: #fd893c;
	border-color: #fd893c; */
	background: #f76c02;
	/* border-color: #976848; */
	color: white;
	/* color: white;
	font-weight: 700; */
}

.abutton--inverse {
	background: transparent;
	color: #ea7200;
}

.abutton--inverse:hover,
.abutton--inverse:active {
	color: white;
	background: #ea7200;
}

.abutton--danger {
	background: #830000;
	border-color: #830000;
}

.abutton--danger:hover,
.abutton--danger:active {
	background: #f34343;
	border-color: #f34343;
}

.abutton:disabled,
.abutton:hover:disabled,
.abutton:active:disabled {
	background: #ccc;
	color: #979797;
	border-color: #ccc;
	cursor: not-allowed;
}

.abutton--small {
	font-size: 0.8rem;
}

.abutton--big {
	font-size: 1.5rem;
}
