table {
	border-collapse: collapse;
	border-radius: 6px;
}

.Teams th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
	font-size: medium;
}

.Teams {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: 100%;
	margin-top: 7rem;
	/*height: 200px;*/
}

td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#teams {
	width: 80%;
	height: 200px;
}
#carousel {
	width: 60%;
	height: 200px;
}
@media (max-width: 430px) {
	#teams {
		width: 100%;
	}
	#carousel {
		width: 100%;
	}
	.Teams th {
		font-size: x-small;
	}
}

@media (max-width: 667px) {
	#teams {
		width: 100%;
	}
	#carousel {
		width: 100%;
	}
	.Teams th {
		font-size: x-small;
	}
}

#teams tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#teams tr:nth-child(odd) {
	background-color: white;
}
#carousel tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}
#carousel tr:nth-child(odd) {
	background-color: white;
}

thead {
	padding: 10px;
}

/* button {
	margin: 0rem !important;
} */

button:hover {
	background-color: white;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

/* //
//
//
//
//
//
//
// */

.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
	margin: 0rem !important;
}
