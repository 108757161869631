table {
	border-collapse: collapse;
	border-radius: 6px;
}

.AdminLeagues th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}

@media (max-width: 430px) {
	.AdminLeagues th {
		font-size: x-small;
	}
}

/*This right here controls the width of the schedule screen */
.AdminLeagues {
	background-color: #e0e0e0;
	/* overflow-x: auto; */
	width: auto;
	margin: auto;
	margin-top: 7rem;
	margin-bottom: 7rem;
	/* height: 200px; */
}

@media (max-width: 700px) {
	.AdminLeagues {
		margin-top: 5rem;
	}
	.AdminLeagues th {
		font-size: xx-small;
	}
	.note h5 {
		font-size: x-small;
	}
}

table td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px 0;
	height: 10px;
}

h2,
h5 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#leagues {
	width: 55%;
	height: 200px;
}

#leagues tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#leagues tr:nth-child(odd) {
	background-color: white;
}

table thead {
	padding: 10px;
}

/* button {
	margin: 0rem !important;
} */

button:hover {
	background-color: white;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

/* #newLeagueButton {
	text-align: center;
	padding: 10px;
	color: white;
	border-radius: 4px;
	background-color: #92562e !important;
	border: #92562e !important;
	margin-left: 10rem !important;
	margin-bottom: 1rem;
} */
.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
	margin: 0rem !important;
}

#masterScheduleDisplay {
	width: 70%;
}

#masterScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#masterScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 700px) {
	#masterScheduleDisplay {
		width: auto;
	}
}

#weekScheduleDisplay {
	width: 70%;
}

#weekScheduleDisplay tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#weekScheduleDisplay tr:nth-child(odd) {
	background-color: white;
}

@media (max-width: 700px) {
	#weekScheduleDisplay {
		width: auto;
	}
}
